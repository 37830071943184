import { SwitchStyleInterface } from "../input_interfaces";

export const inputSwitchDefaultStyle: SwitchStyleInterface = {
    defaultColor: "grey-lighter",
    hoverColor: "grey-light",
    focusColor: "grey",
    activeColor: "primary",
    hoverActiveColor: "primary-dark",
    focusActiveColor: "primary-dark",
    labelClass: "text-black select-none ml-3",
    errorColor: "red",
    saveColor: "green",
    infoColor: "blue",
};
