
import { computed, ComputedRef, defineComponent } from "vue";
import { SwitchStyleInterface } from "../input_interfaces";
import { InputMetaInterface } from "../metaHandler/meta_handler_interfaces";
import MetaHandler from "../metaHandler/MetaHandler.vue";
import { inputSwitchDefaultStyle } from "./input_switch_default_style";
import { metaHandlerDefaultStyle } from "../metaHandler/meta_handler_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";

export default defineComponent({
  components: { MetaHandler },
  name: "InputSwitch",
  emits: ["update:modelValue", "change"],
  props: {
    /**
     * Id of the component
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Name of the component
     */
    name: {
      type: String,
      required: false,
    },
    /**
     * Given value which will react to the change of the switch
     */
    modelValue: {
      type: Boolean,
      required: true,
    },
    /**
     * disables the value and prevents further changes
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Value of the label shown next to the switch
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Shows if the input is required to continue with the current action
     */
    isRequired: {
      type: Boolean,
      default: false,
    },
    /**
     * Configuration Object for Switch Style with
     * following attributes:
     * {
     *      ! defaultColor?: string;
     *      ! hoverColor?: string;
     *      ! focusColor?: string;
     *      ! activeColor?: string;
     *      ! hoverActiveColor?: string;
     *      ! focusActiveColor?: string;
     *      ! labelClass?: string;
     *      ! errorColor?: string;
     *      ! saveColor?: string;
     *      ! infoColor?: string;
     * }
     */
    switchStyle: {
      type: Object as () => SwitchStyleInterface,
      default: Object as SwitchStyleInterface,
    },
    /**
     * if this is set the input saves space for the bottom message and the message
     * can get displayed
     */
    hasMeta: {
      type: Boolean,
      default: true,
    },
    /**
     * Configuration Object for Meta Messages with
     * following attributes:
     * {
     *      ! errorMessage?: string;
     *      ! infoMessage?: string;
     *      ! saveMessage?: string;
     * }
     */
    inputMeta: {
      type: Object as () => InputMetaInterface,
      default: {} as InputMetaInterface,
    },
    /**
     * tabindex for the switch
     */
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const finalInputSwitchStyle: ComputedRef<SwitchStyleInterface> = computed(
      () => {
        return {
          ...inputSwitchDefaultStyle,
          ...metaHandlerDefaultStyle,
          ...getSpaceConfigStyle()?.metaHandler,
          ...getSpaceConfigStyle()?.inputSwitch,
          ...props.switchStyle,
        };
      }
    );
    return {
      finalInputSwitchStyle,
    };
  },
});
