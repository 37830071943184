import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["id", "tabindex"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_meta_handler = _resolveComponent("meta-handler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-row", _ctx.isDisabled ? 'cursor-default opacity-70' : 'cursor-pointer']),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDisabled ? '' : _ctx.$emit('update:modelValue', !_ctx.modelValue)))
    }, [
      _createElementVNode("div", {
        id: _ctx.id,
        tabindex: _ctx.isDisabled ? -1 : _ctx.tabIndex,
        class: _normalizeClass(["w-10 h-6 rounded-full py-0.5 transition-colors focus:outline-none", `bg-${
          _ctx.modelValue
            ? _ctx.finalInputSwitchStyle.activeColor
            : _ctx.finalInputSwitchStyle.defaultColor
        } hover:bg-${
          _ctx.modelValue
            ? _ctx.finalInputSwitchStyle.hoverActiveColor
            : _ctx.finalInputSwitchStyle.hoverColor
        } focus:bg-4${
          _ctx.modelValue
            ? _ctx.finalInputSwitchStyle.hoverActiveColor
            : _ctx.finalInputSwitchStyle.activeColor
        }`]),
        onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.isDisabled ? '' : _ctx.$emit('update:modelValue', !_ctx.modelValue)), ["enter"]))
      }, [
        _createElementVNode("div", {
          class: "h-5 w-5 rounded-full bg-white transform transition-all shadow-lightest",
          style: _normalizeStyle(_ctx.modelValue ? 'margin-left:18px' : 'margin-left:2px')
        }, null, 4)
      ], 42, _hoisted_2),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.id,
            class: _normalizeClass(_ctx.finalInputSwitchStyle.labelClass)
          }, _toDisplayString(_ctx.label) + _toDisplayString(_ctx.isRequired ? " *" : ""), 11, _hoisted_3))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.hasMeta)
      ? (_openBlock(), _createBlock(_component_meta_handler, {
          key: 0,
          inputMeta: _ctx.inputMeta,
          metaStyle: _ctx.switchStyle
        }, null, 8, ["inputMeta", "metaStyle"]))
      : _createCommentVNode("", true)
  ]))
}