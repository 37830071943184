import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "cloud_upload",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_2422",
      "data-name": "Rechteck 2422",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline2",
      d: "M1116.726,98.257a.607.607,0,0,0-.916,0l-2.351,2.579a.721.721,0,0,0,.458,1.213h1.351v2a1,1,0,1,0,2,0v-2h1.351a.721.721,0,0,0,.458-1.213Z",
      transform: "translate(-1104.268 -89.049)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M1123.268,99.049h-.08a6.99,6.99,0,0,0-13.246-1.984,6,6,0,0,0,.326,11.984h13a5,5,0,1,0,0-10Zm0,8h-13a4,4,0,0,1-.218-7.987l1.191-.064.51-1.078a4.99,4.99,0,0,1,9.457,1.413l.247,1.716h1.813a3,3,0,0,1,0,6Z",
      transform: "translate(-1104.268 -89.049)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}